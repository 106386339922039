@import './../css/color-variables';

$cAccent: #3978EF;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

.animation {
  max-width: 350px;
  margin: 0 auto 10px auto;
}
.title-container {
  p {
    // color: $cGray;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
}
.desc-container {
  a {
    color: $cAccent !important;
  }
}
.no-top-margin {
  padding-top: 0 !important;
}
.no-bottom-margin {
  padding-bottom: 0 !important;
}

.accordion-list{
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border-radius: 0.4rem;
    overflow: hidden;
    &__item{
      &+&{
        border-top: 1px solid $cLightGray;
      }
    }
  }
  
  .accordion-item{
    $self: &;
  
    &--opened{    
      #{ $self }__icon{
        transform: rotate(180deg);
      }
          
      #{ $self }__inner{
        max-height: 100rem;
        transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
        transition-duration: 0.2s;
        transition-property: max-height;
      }
      
      #{ $self }__content{
        opacity: 1;
        // transform: translateY(0);
        transition-delay: 0s;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
        transition-property: opacity, transform;
      }
    }
  
    &__line{
      display: block;
      padding: 0.8rem 1.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      z-index: 2;
      position: relative;
      cursor: pointer;
      @media (max-width: 768px) {
        padding: 0.8rem 0rem;
      }
    }
    
    &__title{
      font-size: 1.6rem;
      margin: 0;
      font-weight: 700;
      color: $cBlack;
      @media (max-width: 768px) {
        font-size: 1.2rem;
        line-height: 1.4em;
        max-width: 90%;
      }
    }
    
    &__icon{
      width: 1.2rem;
      height: 1.2rem;
      transition: transform 0.3s ease-in-out;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
      opacity: 0.6;
      @media (max-width: 768px) {
        width: 25px;
        height: 25px;
        position: absolute;
        right: 0;
        background-position: 50% 50%;
        background-size: 25px 25px;
      }
    }
    
    &__inner{
      max-height: 0;
      overflow: hidden;
      text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
      transition-duration: 0.2s;
      transition-property: max-height;
      z-index: 1;
      position: relative;
    }
    
    &__content{
      // opacity: 0;
      // transform: translateY(-1rem);
      transition-timing-function: linear, ease;
      transition-duration: 0.3s;
      transition-property: opacity, transform;
      transition-delay: 0s;
      padding: 0 1.2rem 1.2rem;
    }
    
    &__paragraph{
      margin: 0;
      font-size: 1rem;
      color: $cGray;
      font-weight: 300;
      line-height: 1.3;
    }
  }