
@import './../css/color-variables';
.solution-cards {
    ul.sol-card-list {
        margin-top: 10px;
        list-style-type: disc;
        padding-left: 15px;
        li {
            font-size: 0.85rem;
            line-height: 1.2rem;
            font-weight: 400;
        }
    }
}

section.image-bg-gradient {
    position: relative;
    h2 {
      position: absolute;
      color: $white !important;
      top: 30%;
      left: 25%;
      width: 100%;
      transform: translate(-5%);
    }
    .gradient {
      position: absolute;
    //   top: 0;
    //   left: 0;
      background-color: $black;
      background: linear-gradient(0deg, transparent, $black);
      width: 100%;
        height: 300px;
    }
    @media (max-width: 768px) {
      // position:relative;
      img {
        display: block !important;
      }
      h2 {
        font-size: 2rem;
        top: 50px;
        // left: 0;
        width: 80%;
        transform: translate(-20%)
      }
    }
  }
