@import "../../css/color-variables";

.number-container {
    text-align: left;
    &.border {
        border-left: 1px solid $coral;
        .number{
            // border-left: 2px solid red;
            line-height: 0.8;
            margin-bottom: 5px;
        }
    }
    .number {
        display: block;
        letter-spacing: 0.2px;
        font-weight: 800;
        font-size: 2.5rem;
        padding-left: 15px;
    }
    .subnumber {
        display: block;
        padding-left: 15px;
    }   
}