
@import './../css/color-variables';
.App{
  margin: 0 auto;
  .noMobile {
    @media (max-width: 768px) {
        display: none;
    }
  }
  .container {
    // max-width: 1500px;
    max-width: 1290px;
    padding: 20px;
    margin: 0 auto;
    flex: 12;
    &.small {
      max-width: 1000px;
      text-align: center;
    }
  }
  .pages-container {
    width: 100%;
    margin: 0 auto;
    .page-wrap {
      > div {//animation -> absolute
        // position: absolute;
        width: 100%;
        top: auto;
      }
    }
  }
  .line {
    height: 1px;
    border-top: 1px solid #E7EBF0;
  }


    /* ICON TEXT PARAGRAPH COMPONENT */
  .icon-text-section {
    .container {
        @media (min-width: 784px) {
            > div {
                border-left: 1px solid $white-grey;
                &:first-child{
                    border-left: none;
                }
            }
        }
        
    }
    .icon-text-container {
        margin: 10px auto;
        @media (min-width: 784px) {
            margin: auto;
        }
        .small {
            font-weight: 300;
            font-size: 12px;
        }
        padding: 0 30px;
        .icon {
            width: 20%;
            max-width: 50px;
            @media (min-width: 784px) {
                width: 20%;
                max-width: 100%;
            }
        }
        .title {
            font-size: 1.5rem;
            font-weight: 600;
            margin: 20px auto 5px auto;
            color: $bg-dark-blue;
        }
        
    }
    
}/* ICON TEXT PARAGRAPH COMPONENT */

/* COLORFUL FLEXIBLE CARD IMAGE COMPONENT */
.flexible-card-container {
    .flexible-cardtext {
        font-size: 1rem !important;
        @media (min-width: 905px) {
            font-size: 1.2rem !important;
        }
    }
    .row {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .flexible-card {
        position: relative;
        border-radius: 24px;
        overflow: hidden;
        padding: 30px;
        
        // max-width: 400px;
        margin: 0 auto;
        @media (min-width: 784px) {
            height: 250px;
        }
        @media (min-width: 884px) {
            height: 350px;
            padding: 60px 30px;
        }
        
        .title {
            font-weight: 700;
            color: $black;
            font-size: 1.5rem;
            margin-bottom: 5px;
            @media (min-width: 884px) {
                font-size: 2rem;
                margin-bottom: 10px;
            }
        }
        &.yellow{
            background-color: #FFD64B;
            color: $black;
        }
        &.lightyellow{
            background-color: #FFF4E1;
            color: #717171;
        }
        &.blue{
            background-color: #5CB8FF;
            color: $black;
        }
        &.red{
            background-color: #FF7474;
            color: $black;
        }
        &.lightred{
            background-color: #FFEAEC;
            color: #717171;
        }
        &.green{
            background-color: #DBF6E4;
            color: $black;
        }
        &.img {
            padding: 0;
        }
        .abstract {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            z-index: 0;
            display: none;
            @media (min-width: 445px) {
                display: inline-block;
            }
        }
        .abstract-text{
            width: 65%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        a {
            background-color: $black;
            padding: 10px 15px;
            color: $white;
            font-weight: 600;
            font-size: .8rem;
            margin-top: 20px;
            border-radius: 6px;
            display: inline-block;
            @media (min-width: 905px) {
                padding: 15px 30px;
                border-radius: 12px;
                font-size: 1rem;
            }
            &:hover {
                color: $white;
                background-color: lighten($black,10%);
            }
        }

        .checklist {
            li {
                padding: 5px 0px 2px 35px;
            }
        }
        
    }
    
}/* COLORFUL FLEXIBLE CARD IMAGE COMPONENT */
.addToHomeModal {
    max-width: 400px;
    width: 90%;
    font-size: 1rem;
    padding: 40px 30px 20px 30px;
    background-color: $white;
    z-index: 9999999;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%);
    box-shadow: 0 0 10rem rgb(0 0 0 / 100%);
    position: fixed;
    button.addToHome {
        font-size: .85rem;
        padding: 5px 15px;
        background-color: $black;
        margin-top: 20px;
    }
    button.closeBtn {
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 0;
        background-color: transparent;
    }
}

}