@import '../../css/color-variables';

.arrow-button {

    box-sizing: border-box;
    text-align: center;
    margin: 0;
    padding: 0;
    width: auto;
    .shape-button & {
        span {
            &.hover{
                position: absolute;
                top: 26%;
                right: -5px;
            }
        }
        &.arrow{
            padding-left: 5px;
        }
    }
    .pill & {
        span {
            &.hover{
                top: 13%;
                right: -12px;
            }
        }
    }
    &.arrow{
        display: flex;
        
    }
    span {
        font-weight: 700;
        font-size: 1rem;
        color: $blue;
        transition: all 250ms;
        height: 20px;
        
        &.nohover{
            opacity: 1;
            transform: translate(0px);
          
        }
        &.hover{
            opacity: 0;
            transform: translate(-30px);
        }
    }
    &:hover{
        span.nohover{
            opacity: 0;
            transform: translate(-10px);
            transition: all 250ms;
        }
        span.hover{
            transform: translate(-18px);
            opacity: 1;
            transition: all 250ms;
        }
    }
}