
$line-height-multiplier: 1.4;
@mixin font-size-line-height($size) {
  font-size: $size;
  line-height: ($line-height-multiplier * $size);
}


html {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
}
  
body {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4;
    letter-spacing: 0;
    font-weight: 400;
    color: $grey-300;
    margin-bottom: 30px;
}

.spacer-xs {
  margin-top: 15px;
}
.spacer-sm {
  margin-top: 25px;
}
.spacer-md {
  margin-top: 35px;
}
.spacer-lg {
  margin-top: 50px;
}
.spacer-xl {
  margin-top: 75px;
}
.spacer-xxl {
  margin-top: 100px;
}


.App {
  &.dark-theme {}
  &.light-theme {
    h1, h2, h4 {
      color: $black;
    }
    a {
      color: $black;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        color: lighten($black, 20%);
      }
    }


      .bg-white{
        background: $white;
      }
      .bg-grey{
        background: $bg-light-grey;
      }
      .bg-blue{
        background: $bg-light-blue;
      }
      .bg-green{
        background: $bg-light-green;
      }
      .bg-red {
        background: $bg-light-red;
      }
      .bg-yellow {
        background: $bg-light-yellow;
      }
      .bg-purple {
        background: $bg-light-purple;
      }
      .bg-grey-outline{
        border:1px solid darken($bg-light-grey, 2%);
      }
      .bg-dark-blue{
        background: $bg-dark-blue;
        color: rgb(178, 186, 194);
        .eyebrow {
          color: $vivid-blue;
        }
        h1, h2, h3, h4 {
          color: $white-grey;
        }
      }
      .bg-grey-gradient{
        background: $bg-grey-gradient;
      }

    .top-margin {
      padding-top: 150px;
    }
    .top-margin-reduce {
      margin-top: -100px;
    }

  }

  .simple-page {
    padding-top: 0 !important;
    h1 {
      text-align: center;
    }
    h1,h2,h3,h4,h5 {
      margin: 20px 0px;
      @media #{$breakpoint-sm-up} {
        margin: 30px 0px;
      }
      @media #{$breakpoint-lg-up} {
        margin: 40px 0px;
      }
    }
  }

  section {
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 40px;
    @media #{$breakpoint-md-up} {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @media #{$breakpoint-lg-up} {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    &:first-child {
      // background-color: red;
      padding-top: 80px;
    }

    &.center{
      text-align: center;
    }
    &.naked{
      padding: 0;
      .container {
        padding: 0;
        max-width: 100% !important;
        > div {
          padding: 60px 20px;
          @media #{$breakpoint-md-up} {
            padding: 80px 40px;
          }
          @media #{$breakpoint-lg-up} {
            padding: 100px 60px;
          }
        }
      }
    }
    &.black {
      padding-top: 0px;
      background-color: $black;
      color: $grey-200;
      .title-2, h2 {
        color: lighten($black, 45%);
      }
      .bg-grey {
        background-color: lighten($black, 10%);
        color: $grey-250;
        .title-2, h2 {
          color: lighten($black, 35%);
        }
        .desc-container {
          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
        }
      }
      .solution-cards {
        .card {
          background-color: $black;
        }
      }
      section {
        padding-top: 40px;
        padding-bottom: 10px;
        a {
          color: lighten($black, 45%);
          &:hover {
            color: lighten($black, 55%);
          }
        }
        .checklist {
          li {
            b {
              color: lighten($black, 50%);
            }
          }
        }
      }
    }
  }

  .container {
    &.center {
      text-align: center;
    }
  }

  h1 {
    // font-family: "PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    margin-bottom: 20px;
    font-size: clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem);
    font-weight: 800;
    line-height: 1.1142857142857143;
  }
  
  h2 {
    margin-bottom: 10px;
    // font-family: "PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: clamp(2.5rem, 0.9643rem + 1.4286vw, 2.25rem);
    font-weight: 800;
    line-height: 1.22222;
  }
  
  h4 {
    // font-family: $graphik-medium;
    @include font-size-line-height(1.8rem);
    @media #{$breakpoint-lg-up} {
      @include font-size-line-height(2rem);
    }
  }
  
  p {
    font-weight: 400;
    letter-spacing: 0;
    // font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    @include font-size-line-height(1.2rem);
    @media #{$breakpoint-sm-up} {
      @include font-size-line-height(1.4rem);
    }
  }
  
  li {
    @include font-size-line-height(1.8rem);
    @media #{$breakpoint-md-up} {
      @include font-size-line-height(2rem);
    }
  }
  
  aside, .secondary-text {
    @include font-size-line-height(1.4rem);
    @media #{$breakpoint-md-up} {
      @include font-size-line-height(1.6rem);
    }
  }
  
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
  }

  button{
    background-color: $blue;
    color: $white;
    padding: 0.875rem 1rem;
    font-size: 1rem;
    line-height: 1.3125;
    letter-spacing: 0px;
    transition: background-color 250ms;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    font-weight: 700;
    &:hover {
      cursor: pointer;
      background-color: $blue;
    }
  }
  
  button {
    text-align: center;
    border: none;
    @include font-size-line-height(1.4rem);
    transition: background-color 0.2s ease-in;
    transition: border 0.2s ease-in;
    @media #{$breakpoint-md-up} {
      padding: 3px 15px 3px 15px;
      @include font-size-line-height(1.6rem);
    }
  }

}
  
  

  