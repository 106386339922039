@import './../../css/color-variables';

.footer-container {
    width: 100%;
    @media (max-width: 784px) {
        .upper {
            padding-top: 0 !important;
            .column1 {
                padding-top: 0;
                margin-top: 0 !important;
                padding-bottom: 0;
                margin-bottom: 0 !important;
            }
        }
    }
    section {
        &.upper {
            .column1 {
                text-align: left;
                .logo {
                    max-width: 35px;
                    margin-bottom: 20px;
                }
                h5 {
                    font-weight: 700;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 16px;
                    line-height: 1.2;
                }
                @media (max-width: 784px) {
                    text-align: center;
                    margin: 20px auto;
                    .logo {
                        margin: 20px auto;
                    }
                }
            }
            .column2 {
                @media (max-width: 768px) {
                    .list-item {
                        text-align: left;
                        padding: 0px 20px;
                        line-height: 1rem;
                    }
                }
            }
        }
    }
    .lower{
        border-top: 1px solid #E7EBF0;
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        font-size: 14px;
    }
    .badge-container{
        div{
            margin-left: 0px;
            padding-left: 0px;

        }
    }
}