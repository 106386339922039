@import './../css/color-variables';

.card {
    background-color: white;
    border-radius: 12px;
    padding: 40px 20px;
    text-align: left;
    z-index: 999;
    img {
        width: 25px;
    }
    h5 {
        font-size: 18px;
        font-weight: 600;
        margin: 10px 0px;
    }
    h6 {
        font-size: 14px;
        font-weight: 600;
        color: #FF4D58;
        margin: 10px 0px 3px 0px;
    }
    p {
        font-size: 14px;
        line-height: 18px;
    }
}
.intro-container {
    z-index: 9;

   .card {
        height: 100%;
        b {
            font-weight: 600;
        }
   }
}

    .enterprise-solution-img {
        width: 30% !important;
        max-width: 400px !important;
        position: absolute;
        top: 40%;
        left: 65%;
        z-index: 0;
        max-width: 100px;
        display: none !important;
        @media (min-width: 784px) {
            display: block !important;
        }
    }
    .team-solution-img {
        display: none !important;
        @media (min-width: 784px) {
            display: block !important;
        }
    }


    
        @media (max-width: 768px) {
            .solution-cards {
                .col-xs-12  {
                    padding: 10px 0;
                    .card {
                        padding: 30px 20px;
                    }
                }
            }
        }
    