.shape-button {
    background-color: #007FFF;
    color: white !important;
    padding: 0.875rem 1rem;
    font-size: 1rem;
    line-height: 1.3125;
    letter-spacing: 0px;
    border-radius: 10px;
    transition: background-color 250ms;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    font-weight: 700;
    &:hover {
        background-color: darken(#007FFF, 10%);
    }
    a, span {
        color: white;
        display: inline;
    }
    &.pill {
        padding: 0.25rem 0.925rem;
        border-radius: 100px;
    }
    &.large-pill {
        padding: 0.825rem 1.5rem;
        border-radius: 100px;
    }
    &.border {
        border: 2px solid #007FFF;
        a, span {
            color: #007FFF;
        }
        background-color: transparent;
        transition: all 250ms;
        &:hover{
            background-color: lighten(#007FFF, 0%);
            transition: all 250ms;
            a, span {
                color: white;
                transition: all 250ms;
            }
        }
    }
}