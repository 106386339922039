.tabs{
    font-family:arial;
    margin:0 auto;
    width:70%;
    text-align:center;
    margin-top:15vh;
  }
  
  
  
//   .tab-buttons{
//     margin-bottom:50px;
//     .button {
//         .active{
//             border-bottom:2px solid #42b3f4;
//             color:black;
//           }
//     }
    
//   }
  
  .tab-buttons{
    width: 300px;
    height: 55px;
    margin: 50px auto 0 auto;
    position: relative;        
    overflow: hidden;
    border-radius: 8px;
    background: #F2F2F2;

    > span {
      width: 150px;
      height: 55px;
      display: table-cell;
      position: relative;       
      z-index: 100;
      text-align: center;
      vertical-align: middle;
      font-weight: 600;
      color: #666565;
      cursor: pointer;
      
      transition: color ease-in 0.23s 0.09s;
      -moz-transition: color ease-in 0.23s 0.09s;
      -webkit-transition: color ease-in 0.23s 0.09s;

      &.active {
        color: #222222;
        
        transition: color ease-in 0.23s 0.09s;
        -moz-transition: color ease-in 0.23s 0.09s;
        -webkit-transition: color ease-in 0.23s 0.09s;
      }
    }

    .switch_bg {
      width: 140px;
      height: 40px;      
      position: absolute;
      top: 7.5px;
      left: 7.5px;
      border-radius: 6px;

     

      transition: all ease-in 0.23s;
      -moz-transition: all ease-in 0.23s;
      -webkit-transition: all ease-in 0.23s;
      background: #fff;
   

      &.right{
        left: 152px;
        transition: all ease-in 0.23s;
        -moz-transition: all ease-in 0.23s;
        -webkit-transition: all ease-in 0.23s;
      }
    }
    .radio_btns{
      display: none;
    }

  }