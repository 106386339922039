$thumb-size: 25px;
@import './../../css/color-variables';


@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  // background-color: red;

  &:active {
    cursor: grabbing;
  }
}

.sliderwrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 40px calc($thumb-size / 2);
  padding-top: 1.6rem;
  height: calc($thumb-size + 1.6rem);
  
  .input-sliderwrapper {
    width: calc(100% + $thumb-size);
    margin: 0 calc($thumb-size / -2);
    position: absolute;
    height: $thumb-size;

    .sliderinput {
      position: absolute;
      width: 100%;
      pointer-events: none;
      left: 0;
      appearance: none;
      height: 100%;
      opacity: 0;
      z-index: 3;
      padding: 0;
    
      &::-ms-track {
        @include track-styles;
      }
    
      &::-moz-range-track {
        @include track-styles;
      }
    
      &:focus::-webkit-slider-runnable-track {
        @include track-styles;
      }
    
      &::-ms-thumb {
        @include thumb-styles;
      }
    
      &::-moz-range-thumb {
        @include thumb-styles;
      }
    
      &::-webkit-slider-thumb {
        @include thumb-styles;
      }

    };

  }
  
  .slidercontrol-wrapper {
    width: 100%;
    position: absolute;
    height: $thumb-size;

    .rail {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 6px;
      border-radius: 3px;
      // background: lighten($grey-100, 10%);
      background: lighten($coral, 10%);
    }
    
    .inner-rail {
      position: absolute;
      height: 100%;
      background: $grey-100;
      // opacity: 0.5;
      border-radius: 3px;
    }
    
    .control {
      width: $thumb-size;
      height: $thumb-size;
      border-radius: 50%;
      position: absolute;
      background: $coral;
      top: 50%;
      margin-left: calc($thumb-size / -2);
      transform: translate3d(0, -50%, 0);
      z-index: 2;
      transition: width 250ms, height 250ms;
      
      // &:hover {
      //   width: 35px;
      //   height: 35px;
      //   margin-left: calc(35px / -2);
      //   transition: width 250ms, height 250ms;
      // }

    
      
    }

    .number-box {
      width: $thumb-size;
      height: $thumb-size;
      border-radius: 50%;
      position: absolute;
      background: $coral;
      top: 50%;
      margin-left: calc($thumb-size / -2);
      transform: translate3d(0, -50%, 0);
      z-index: 2;
      transition: width 250ms, height 250ms;
      
      background: $coral;
      width: 100px;
      height: 25px;
      border-radius: 6px;
      font-weight: 800;
      text-align: center;
      margin-left: -50px;
      color: $white;
      visibility: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-style: solid;
        border-color: $coral transparent transparent transparent;
        margin-left: -5px;
        border-width: 5px;
      }

      &.active {
        visibility: visible;
      }
    }

    .pulse {
      animation: pulse-animation 2s infinite;
    }
    
    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba($coral, 0.2);
      }
      100% {
        box-shadow: 0 0 0 20px rgba($coral, 0);
      }
    }
  }
  
  
}



