@import './../css/color-variables';

.circle-container {
  .circle {
    border-radius: 100%;
    border: 5px solid white;
    font-weight: 800;
    text-align: center;
    position: absolute;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    &.large {
      width: 200px;
      height: 200px;
      background-color: lighten($purple, 30%);
      color: $purple;
      font-size: 48px;
      top: 95%;
    }
    &.medium {
      width: 150px;
      height: 150px;
      background-color: lighten($green, 30%);
      color: $green;
      font-size: 32px;
      left: 32%;
    }
    &.small {
      width: 100px;
      height: 100px;
      background-color: lighten($vivid-blue, 30%);
      color: $vivid-blue;
      font-size: 22px;
      left: 36%;
      top: 98%;
    }
    .sub {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 500;
      
    }
  }
}

.intro-stats {
  div {
    h5 {
      font-size: 4rem;
      font-weight: 400;
      color: $vivid-blue;
      border-top: 1px solid $vivid-blue;
      padding-top: 20px;
    }
    p {
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }
  @media (max-width: 768px) {
    div {
      h5 {
        font-size: 2rem;
        padding-top: 10px;
      }
      p {
        font-size: .85rem;
        line-height: 1.1rem;
      }
    }
  }
  
}

.title-0 {
  font-size: 6rem !important;
  // font-weight: 600 !important;
}
.footprint {
  max-width: 1000px;
  p {
    font-size: .75rem;
    line-height: 0.9rem !important;
    margin-bottom: 5px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
}

.hero-desc {
  a {
    font-weight: 400;
    color: $blue !important;
    transition: all 250ms;
    &:hover {
      text-decoration: underline;
      transition: all 250ms;
    }
  }
  b {
    font-weight: 600;
    color: darken($grey-300, 15%);
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: .95rem;
  }
}

.hero-graph {
  position: relative;
  .graph-content {
    position: absolute;
    // border: 1px solid red;
    max-width: 600px;
    margin-top: 100px;
    top: 0;
    margin-left: 60px;
    .eyebrow {
      font-weight: 600;
      margin-top: -10px;
      // position: absolute;
      text-align: right;
      // margin-right: 50px;
      font-size: 22px;
      margin-left: 35px;
    }
    .percent {
      margin-bottom: 20px;
      position: absolute;
      text-align: right;
      top: -50px;
      width: 100%;
      right: 0;
      // margin-right: 50px;
      font-weight: bold;
      span {
        color: red;
      }
    }
    h4 {
      // border: 1px solid red;
      font-size: 6rem;
      font-weight: 800;
      font-family: Arial, Helvetica, sans-serif;
      display: flex;
      margin-left: 10px;
      &.num {
        margin-left: 0px;
        &::before{
          content: '$ ';
          font-size: 2rem;
          display: flex;
        }
      }
    }
    .paragraph{
      text-align: left;
      max-width: 400px;
    }
  }
  @media (max-width: 768px) {
    .graph-content {
      position: absolute;
      max-width: 600px;
      margin-top: -40px;
      top: 0;
      margin-left: 0;
      .eyebrow {
        font-weight: 600;
        margin-top: -20px;
        font-size: 0.75rem;
        // margin-left: 0;
        width: 30%;
        line-height: .95rem;
      }
      .percent {
        display: none;
      }
      h4 {
        font-size: 2.5rem;
        &.num {
          &::before{
            font-size: .85rem;
          }
        }
      }
    }
  }
}

// .case-number-container {
//   display: flex;
//   margin: 0 auto;
//   div.case-number-card {
//     width: 20%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     min-height: 125px;
//     border-right: 1px solid $white-grey;
//     border-top: 1px solid $white-grey;
//     border-bottom: 1px solid $white-grey;
//     &:first-child{
//       border-left: 1px solid $white-grey;
//       border-top-left-radius: 24px;
//       border-bottom-left-radius: 24px;
//     }
//     &:last-child{
//       border-top-right-radius: 24px;
//       border-bottom-right-radius: 24px;
//     }
//     .case-number {
//       font-size: 3rem;
//       line-height: .75em;
//       font-weight: bold;
//       color: $black;
//     }
//     .case-number-title {
//       font-size: .9rem;
//       font-weight: 600;
//       margin-top: 5px;
//       color: $grey-200;
//     }

   

//     &.employee {
//       // width: 15%;
//     }
//     &.turnover {
//       // width: 13%;
//     }
//     &.attrition {

//     }
//     &.disengagement {

//     }
//     &.productivity {

//     }
//   }
// }

img.title-logo {
  width: 10%;
  margin: 25px auto;
}

.slider-card-container {
  .container {
    .slider-card {
      height: 100%;
      height: 310px;
      border-radius: 12px;
      padding: 20px;

      position: relative;
      // display: flex;
      // flex-direction: column;
      
      &.blue {
        background-color: #EBF4FF;
      }
      &.purple {
        background-color: #F8EFFF;
      }
      &.red {
        background-color: #FFEAEC;
      }
      &.yellow {
        background-color: #FAF6CE;
      }
      &.green {
        background-color: #DBF6E4;
      }
      &.orange {
        background-color: #FFF3E0;
      }

      h6 {
        font-weight: 700;
        color: $black;
        font-size: 1.3rem;
      }
      .number {
        font-size: 3rem;
        line-height: .75em;
        font-weight: bold;
        color: $black;
        margin: 35px auto;
        text-align: center;
      }
      .bottom {
        // position: absolute;
      }
      .details {
        font-size: 0.75rem;
        font-weight: 600;
        color: $black;

        position: absolute;
        bottom: 20px;
        width: 85%;
        .row > div {
          padding-top: 8px;
          padding-bottom: 8px;
        }
        .row:first-child {
          border-bottom: 1px solid $grey-100;
        }
          .dark {
            width: 10px;
            height: 10px;
            border-radius: 2px;
            background-color: $grey-400;
          }
          .light {
            width: 10px;
            height: 10px;
            border-radius: 2px;
            background-color: $grey-100;
          }
      }
      .percentage-details {
        position: absolute;
        bottom: 25px;
        width: 85%;
        .row > div {
          padding: 0px 10px;
        }
        .main {
          font-size: 2.5rem;
          font-weight: 400;
          color: $black;
          border-right: 1px solid $grey-100;
          text-align: center;
        }
        .number-caption {
          font-size: 0.85rem;
          font-weight: 600;
          color: $black;
        }
        .text-caption {
          font-size: 0.65rem;
          // font-weight: 600;
          color: $grey-300;
        }
      }
      .graph-details {
        // position: relative;
        .row > div{
          vertical-align: bottom;
        }
        .bar {
          display: block;
          height: 100%;
          width: 20px;
          // border-radius: 8px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          // background-color: $grey-200;
          background-color: $coral;
          z-index: 999;
          position: relative;
          transform: rotatex(180deg);
          transform-origin: top;
          margin-top: 80px;
        }
        .quarter {
          font-size: 0.65rem;
          font-weight: 500;
          color: $grey-300;
          // text-align: center;
          margin: 10px auto;
          position: relative;
          left: 50%;
          transform: translate(-35%);
        }
        .quarter-wrapper {
          margin-top: -50px;
          position: absolute;
          bottom: -10px;
          left: 50%;
          padding: 0px 20px;
          transform: translate(-50%);
        }
        .line {
          border: solid transparent;
          border-bottom: 0.5px solid $grey-100;
          background-color: transparent;
          width: 100%;
          height: 0;
          position: absolute;
          left: 0px;
          z-index: 0;
          &.one {
            // bottom: 48px;
            bottom: 35px;
          }
          &.two {
            // bottom: 65px;
            bottom: 52px;
          }
          &.three {
            // bottom: 85px;
            bottom: 72px;
          }
          &.four {
            // bottom: 105px;
            bottom: 92px;
          }
        }
      }
    } 
  }
  @media (max-width: 768px) {
    .container {
      .slider-card {
        height: 160px;
        h6 {
          font-weight: 700;
          color: $black;
          font-size: 1.5rem;
        }
        .number {
          font-size: 3rem;
          margin: 5px auto;
        }
        .bottom {
          display: none;
        }
      }
    }
  }
}

.employee-container{
  margin: 0 auto;
  text-align: center;
  .employee-number{
    font-size: 8rem;
    @media (max-width: 768px) {
      font-size: 4rem;
    }
  }
  
}



.timeline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .timeline {
    height: 100px;
    width: 5px;
    background-color: #e5e5e5;
    margin-left: 13px;
  }

  .stub1 {
    line-height: 300px;
    font-size: 24px;
    background-color: #eae4e4;
  }
  
  .stub2 {
    height: 1000px;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    background-color: #e5e5e5;
    font-weight: bold;
  }
  
  .circleWrapper {
    position: relative;
  }
  
  .timelinewrapper {
    text-align: left;
    align-items: start;
    position: absolute;
    height: 100%;
    display: contents;
  }
  
  .title {
    position: absolute;
    top: 0;
    margin-left: 80px;
    width: 10%;
    font-weight: 600;
    font-size: 1.5rem;
    color: $black;
  }
  
  .message {
    position: absolute;
    top: -50%;
    // left: 200%;
    margin-left: 20%;
    width: 80%;
 
  }

  @media (max-width: 768px) {
    display: block;
    .timeline {
      height: 50px;
      width: 5px;
      background-color: #e5e5e5;
      margin-left: 13px;
    }
    .title {
      position: relative;
      top: 0;
      margin-left: 80px;
      width: 10%;
      font-weight: 600;
      font-size: 1.5rem;
      color: $black;
      margin-bottom: 15px;
    }
    
    .message {
      position: relative;
      top: 0;
      // left: 200%;
      margin-left: 20%;
      width: 80%;
   
    }
  }

}

.whymeet-cta {
  .col {
    text-align: left;
    font-size: 1.2rem;
    margin-top: 60px;
    &:first-child {
      border-right: 1px solid $grey-100;
      padding-right: 50px;
    }
    &:last-child {
      padding-left: 50px;
    }
    h5 {
      font-size: 1.5rem;
      font-weight: 600;
      color: $black;
      margin-bottom: 10px;
    }
    a.cta {
      background-color: $black;
      color: $white !important;
      font-size: 1rem;
      font-weight: 600;
      display: block;
      border-radius: 8px;
      padding: 10px 20px;
      text-align: center;
      margin: 20px 0px;
      width: 200px;
    }
  }
  @media (max-width: 768px) {
    .col {
      &:first-child {
        border-right: none;
        padding-right: 0;
        padding-bottom: 50px;
        border-bottom: 1px solid $grey-100;
      }
      &:last-child {
        padding-left: 15px;
      }
    }
  }
}



.career-card-container {
  border: 1px solid $grey-100;
  border-radius: 12px;
  padding: 30px 20px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  h5 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: $grey-400;
  }
  .location {
    font-size: .75rem;
    margin-top: -8px;
  }
  h6 {
    font-weight: 600;
    font-size: 0.85rem;
    margin: 15px 0px 5px 0px;
    color: $grey-400;
  }
  .content {
    font-size: 0.85rem;
    margin-bottom: 25px;
    ul {
      font-size: 0.85rem;
      list-style-type: disc;
      padding-left: 10px;
      li {
        font-size: 0.85rem;
        line-height: 1.2rem;
        padding: 3px 0px;
        b {
          font-weight: 600;
          color: $grey-300;
        }
      }
    }
  }
  a.cta {
    background-color: $black;
    display: block;
    color: $white;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    padding: 10px 20px;
    margin: 30px auto 0px auto;
    width: 80%;
    font-size: 1rem;
    margin-top: auto;
    &:hover {
      background-color: $grey-400;
      color: $white;
    }
  }
  &.investor {
    background-color: $black;
    color: $white;
    
    h5 {
      color: $white;
      font-size: 1.5rem;
      font-weight: bold;
    }
    h6 {
      color: $white;
      font-size: .95rem;
      font-weight: bold;
    }
    .content {
      color: $white;
      font-size: .95rem;
    }
    a.cta {
      background-color: $grey-100;
      color: $black;
      &:hover {
        background-color: $grey-200;
        color: $black;
      }
    }
  }
}




