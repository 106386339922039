.card-container {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    display: inline-block;
    &.border {
        border: 1px solid rgb(224, 227, 231);
    }
    &.noGutter {
        padding: 0;
        
        .text-wrapper {
            padding: 30px;
            text-align: left;
        }
        img {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .text-wrapper {
        &.eyebrowColor {
            .eyebrow {
                color: #FF4D58;
            }
        }
        &.titleColor {
            h2, h3, h4 {
                color: #007FFF;
            }
        }
        &.contentColor {
            p {
                color: #007FFF;
            }
        }
        .eyebrow {
            font-weight: 800;
            font-size: 1rem;
            vertical-align: middle;
        }
        .icon {
            margin-right: 5px;
        }
        p{
            font-size: 1.2rem;
            line-height: 1.25;
            margin: 5px auto 10px auto;
        }
    }
    
    img {
        margin-top: 40px;
    }
}