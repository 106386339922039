
@import './../css/color-variables';



.pill-container {
    .pill {
        margin: 0 10px 10px 0 !important;
    }
}
@media (max-width: 768px) {
    .process-container {
        .col-xs-1 {
            img {
                    min-width: 35px;
                    margin-left: -15px;
                }
            }
    .row {
            .col-xs-4 {
                h5 {
                        font-size: .8em;
                        line-height: 1.2em;
                    }
                p {
                    display: none;
                    font-size: .75em;
                    line-height: 1.2em;
                }
            }

        }
    }
    .boxed-checklist {
        img {
            min-width: 35px;
        }
    }
    .pill-container {
        .pill:nth-child(n+6){
            display: none;
        }
    }
}