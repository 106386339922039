@import './../css/color-variables';

.home-hero {
    padding-bottom: 0 !important;
    .container {
        padding-bottom: 0 !important;
        > div {
            padding-bottom: 0 !important;
        }
    }
}
.portrait-img {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  
    .container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        > div {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            
        }
        .text-col {
            .intro-container {
                .title-container{
                    padding-top: 80px !important;
                }
                .checklist {
                    padding-bottom: 80px !important;
                }
            }
        }
        .img-col {   
            > div {
                display: inline-block;
                height: 100% !important;
                
                > img {
                    display: inline-block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                }
            }
           
        }
    }
    
}
.intro-container {

    &.image-text{
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.dark{

        }
        &.light{
            .title-2 {
                color: $white;
            }
        }
        @media (max-width: 768px) {
            top: 15%;
            left: 5%;
            transform: translate(0%, 0%);
        }
    }
    
    &.center {
        text-align: center;
    }
    .pill {
        border: 1px solid #EFEFEF;
        padding: 10px 20px;
        display: inline-block;
        border-radius: 20px;
        margin-bottom: 40px;
        font-size: 12px;
        font-weight: 600;
    }
    .title-container {
        .red {
            color: #FF4D58;
        }
        .eyebrow {
            color: #FF4D58;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 30px;
        }
        .title-1 {
            font-size: 80px;
            font-size: 5rem;
            font-weight: 700;
            line-height: 75px;
            @media (min-width: 884px) {
                font-size: 150px;
                font-size: 9.3rem;
                line-height: 120px;
            }
        }
        .title-2 {
            font-size: 45px;
            font-size: 2.813rem;
            font-weight: 700;
            line-height: 45px;
            @media (min-width: 884px) {
                font-size: 75px;
                font-size: 4.6rem;
                line-height: 70px;
            }
            span.red {
                color: #FF4D58;
            }
        }
        .subtitle {
            font-size: 25px;
            font-weight: 600;
            line-height: 30px;
            @media (min-width: 884px) {
                line-height: 40px;
            }
        }
        .process-eyebrow {
            margin-bottom: 20px;
            .bar {
                display: inline-block;
                width: 30px;
                height: 2px;
                background-color: black;
                vertical-align: middle;
                margin-right: 10px;
            }
            .name {
                font-size: 14px;
                font-weight: 600;
                color: black;
            }
        }
    }
    .desc-container {
        font-size: 22px;
        // font-weight: 700;
        // line-height: 120px;
        margin: 20px 0px;
    }
    a.button {
        color: white;
        background-color: black;
        font-weight: 600;
        padding: 15px 30px;
        display: inline-block;
        border-radius: 8px;
        &:hover {
            color: white;
        }
    }
    a.naked-btn {
        color: black;
        font-weight: 600;
        padding: 15px 0px;
        display: inline-block;
        &:hover {
            color: black;
        }
    }
    a.naked-cta {
        width: 100%;
        color: $coral;
        // color: $vivid-blue;
        font-weight: 600;
        padding: 15px 0px;
        // display: inline-block;
        margin-left: 20px;
        &:hover {
            color: darken($coral, 20%);
        }
        span {
            display: inline-block;
            vertical-align: middle;
            img {
                width: 30px !important;
                margin-left: 10px;
            }
        }
    }
    .platform-title {
        font-weight: 600;
        font-size: 16px;
        @media (max-width: 768px) {
            text-align: center;

        }
    }
    .badge-container {
        padding-top: 10px;
        @media (max-width: 768px) {
            text-align: center;
            margin-bottom: 40px;
            margin-left: 30px;
        }
        button {
            background-color: transparent;
            padding: 0;
        }

        .tooltip {
           
            display: inline-block;
            --width: 40px;
            --height: 40px;
            --tooltip-height: 35px;
            --tooltip-width: 90px;
            --gap-between-tooltip-to-button: 18px;
            // --button-color: #222;
            --tooltip-color: #fff;
            margin-top: 10px;
            margin-right: 25px;
            width: var(--width);
            height: var(--height);
            background: var(--button-color);
            position: relative;
            text-align: center;
            border-radius: 0.45em;
            font-family: "Arial";
            transition: background 0.3s;
            &::before {
                position: absolute;
                content: attr(data-tooltip);
                width: var(--tooltip-width);
                height: var(--tooltip-height);
                // background-color: #555;
                background-color: $black;
                font-size: 0.9rem;
                color: #fff;
                font-weight: 600;
                border-radius: .25em;
                line-height: var(--tooltip-height);
                bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
                left: calc(50% - var(--tooltip-width) / 2);
              }
              &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border: 10px solid transparent;
                // border-top-color: #555;
                border-top-color: $black;
                left: calc(50% - 10px);
                bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
              }
              &::after, &::before {
                opacity: 0;
                visibility: hidden;
                transition: all 0.5s;
              }
              &:hover:before, &:hover:after {
                opacity: 1;
                visibility: visible;
              }
              
              &:hover:after {
                bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
              }
              
              &:hover:before {
                bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
              }
              @media (max-width: 768px) {
                --width: 50px;
                --height: 50px;
            }
        }
          
          
        
    }
    .cta-container {
        margin-top: 30px;
        text-align: center;
        .naked-btn{
            margin-top: 20px;
        }
        @media (min-width: 884px) {
            margin-top: 0px;
            text-align: initial;
            .naked-btn{
                margin-top: 0px;
            }
        }
        a {
            margin-right: 20px;
            span {
                display: inline-block;
                vertical-align: middle;
                img {
                    width: 12px;
                    margin-left: 5px;
                }
            }
        }
    }
    .checklist {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        li {
            background: url('../assets/checkmark.png') no-repeat left center;
            // padding: 5px 10px 5px 25px;
            padding: 10px 10px 15px 35px;
            list-style: none;
            margin: 0;
            vertical-align: middle;
            font-size: 16px;
            line-height: 22px;
          }
          b {
            font-weight: 600;
            color: darken($grey-300,10%);
          }
          &.redcross li {
            background: url('../assets/cross.png') no-repeat left center;
          }
          &.blackcross li {
            background: url('../assets/blackcross.png') no-repeat left center;
          }
        a.naked-cta {
            // margin: 0 auto;
            // width: 100px;
            right: 0;
            flex-grow: 1;
            text-align: right;
            @media (max-width: 768px) {
                padding-right: 25px;
            }
        }
        
    }

    
    .process-container {
        h5 {
            font-size: 20px;
            font-weight: 600;
        }
        p {
            font-size: 14px;
            line-height: 18px;
            max-width: 200px;
            margin: 10px auto 0px auto;
        }
    }

    .boxed-checklist-container {
        .boxed-checklist {
            border: 1px solid #EAEAEA;
            border-radius: 12px;
            padding: 5px 10px;
            max-width: 550px;
            margin: 0px 0px 20px 0px;
            h6 {
                font-size: 16px;
                font-weight: 600;
            }
            p {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    
}
.phone-img {
    max-width: 344px;
}
.card {
    background-color: white;
    border-radius: 12px;
    padding: 40px 20px;
    text-align: left;
    img {
        width: 25px;
    }
    h5 {
        font-size: 18px;
        font-weight: 600;
        margin: 10px 0px;
    }
    h6 {
        font-size: 14px;
        font-weight: 600;
        color: #FF4D58;
        margin: 10px 0px 3px 0px;
    }
    p {
        font-size: 14px;
        line-height: 18px;
    }
}