
//reset styles
@import "reset";
//grid from: https://github.com/drewbot/sass-flexbox-grid
@import "grid-variables";
@import "visibility-mixins";
@import "grid-mixins";
@import "grid";
@import "visibility";
//theme
@import "color-variables";
@import "default-elements";
// @import "unique-styles";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin: 0;
  font-family: 'Poppins', 'elza', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
