
.background-shape {
    width: 100%;
    // background-color: #E6F2FF;
    
    height: 800px;
    position: absolute;
    top: -200px;
    z-index: -1;
    max-width: 1800px;

    border-radius: 80px;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;
    transform: skewY(-8deg);
    
    &.green {
        background-color: #e5f6f5;
    }
    &.blue {
        background-color: #EBF4FF;
    }
    &.red {
        background-color: #FFEAEC;
    }
    &.yellow {
        background-color: #FAF6CE;
    }
    &.purple {
        background-color: #F8EFFF;
    }


    &.longer {
        height: 1000px;
        @media (max-width: 768px) {
            // height: 1150px;
            height: 950px;
        }
    }

}

.center-step {
    margin: 20px auto;
    width: 40px !important;
}

.small-intro {
    margin-top: 20px;
    font-size: 14px;
}


.lottie-animation {
    margin-top: -15%;
    position: static;
    height: 100%;
    z-index: -99999;
}

.intro-container {
    position: relative;
}


.background-container {
    width: 85%;
    min-width: 500px;
    position: relative;
    max-width: 1250px;
    background-color: #fff;
    border-radius: 24px;
    padding: 50px 60px 0px 60px;
    margin: 20px auto;
    box-shadow: 0 3px 30px rgb(0 0 0 / 0.05);

    @media (min-width: 1200px) {
        .intro-container {
            padding: 40px 60px;
        }
    }
    &.edgeless {
        padding: 0px;
    }

    @media (max-width: 768px) {
        width: 100%;
        min-width: initial;
        max-width: initial;
        background-color: #fff;
        border-radius: 0px;
        padding: 40px 20px 0px 20px;
        margin: 0px auto;
    }
}

