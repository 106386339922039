@import './../css/color-variables';

.about {
    &.top-margin {
        padding-bottom: 0 !important;
    }
    .intro {
        color: $grey-400;
        p {
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin: 15px auto;
        }
        .btn {
            display: inline-block;
            margin-left: 40px;
        }
        
    }
}
h3 {
    font-weight: bold;
    font-size: 1.8rem;
    color: $grey-400;
    margin-top: 40px;
    margin-bottom: 8px;
}
.numbers {
    margin-top: 20px !important;
    padding-left: 0 !important;
    > div {
        &:first-child{
            padding-left: 0;
        }
    }
    .number-container {
        border-color: $grey-100;
        .number {
            color: lighten($vivid-blue, 20%);
        }
        .subnumber {
            font-size: 0.8rem;
            color: $grey-300;
            font-weight: 500;
        }
    }
}

.aboutcards {
    .col {
        height: 100%;
        min-height: 200px;
        display: flex;
    }
}

.sales-btn {
    background-color: $vivid-blue;
    color: $white !important;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    transition: all 250ms;
    &:hover{
        background-color: lighten($black, 5%);
        transition: all 250ms;
    }
}

b {
    font-weight: 800;
    &.red {
        color: #FF4D58;
    }
}