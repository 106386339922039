@import './../css/color-variables';

.salescontent {
    .checklist {
        display: none;
        @media (min-width: 784px) {
            display: block;
        }
    }
}
@media (max-width: 768px) {
    section.top-margin {
        padding-top: 80px !important;
    }
}

#contact-form {
    border: 1px solid #f1f1f1;
    background-color: $white;
    box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
    padding: 40px 10px;
    border-radius: 4px;
    .form-group {
        label {
            font-family: "Helvetica Neue",Helvetica,"Segoe UI",Tahoma,Arial,sans-serif;
            display: block;
            font-size: .9rem;
            font-weight: 600;
            margin: 0.5rem 0;
            line-height: 1.25;
            letter-spacing: 0.22px;
            .red {
                color: #e01e5a;
                font-size: 1.2rem;
                font-weight: 600;
            }
            .optional {
                font-weight: 400;
            }
        }
        .error { 
            // border-color: #e01e5a;
            border-color: $coral;
        }
        .error-text {
            color: $coral;
            font-size: .9rem;
            line-height: 1.1em;
            margin-top: 10px;
            font-weight: 500;
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 95%;
            background-position-y: 15px;
            border: 1px solid #dfdfdf;
            border-radius: 2px;
            margin-right: 2rem;
            padding: 1rem;
            padding-right: 2rem;
        }
        textarea, input, select {
            width: 100%;
            font-size: 1.2rem;
            border: 1px solid #a9a9a9;
            border-radius: 4px;
            font-weight: 400;
            line-height: 1.25;
            letter-spacing: normal;
            color: #777777;
            padding: 1.15rem 1rem;
            font-family: "Helvetica Neue",Helvetica,"Segoe UI",Tahoma,Arial,sans-serif;
            resize: none;
            // appearance:none;
            &::placeholder {
                // color: #a9a9a9;
            }
        }
    }
    .disclosure {
        font-size: 1rem;
        color: #777777;
        font-weight: 400;
        margin-bottom: 20px;
        a {
            text-decoration: underline;
            color: $blue;
        }
    }
    .submit.btn {
        padding: 10px 100px;
        font-size: 1rem;
        background-color: $black;
        border-radius: 4px;
        text-transform: uppercase;
    }
}
.success-msg {
    svg {
        max-height: 600px;
    
    }
}

.salesform {
    .salesfields {
        @media (max-width: 768px) {
            padding: 0;
            #contact-form {
                padding: 0 5px;
                .col-xs-12 {
                    padding-bottom: 0;
                }
                .submit {
                    margin: 10px auto;
                    width: 100%;
                }
                padding-bottom: 20px;
            }
            
        }
    }
}