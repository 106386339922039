@import './../css/color-variables';

.blog-container {
    .no-top-margin {
        padding-top: 0;
    }
    .blog-divider {
        padding: 0% 5%;
        margin: 0;
    }
    .section-title {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 5px;
        color: $grey-300;
    }
    section {
        &.featured {
            padding-top: 120px;
            padding-bottom: 0px;
            .main-image {
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .intro-container {
                padding: 20px 0px 40px 0px;
                .pill {
                    margin-bottom: 10px;
                    color: $blue;
                    text-transform: uppercase;
                    font-weight: 800;
                }
                .title-container {
                    h1 {
                        transition: all 400ms;
                        margin-bottom: 30px;
                        font-size: 2.5rem;
                        @media (min-width: 884px) {
                            font-size: 3.5rem;
                            line-height: 1.15em;
                            letter-spacing: 0.08rem; 
                        }
                           
                    }
                    p {
                        color: $grey-300;
                    }
                }
            }
        }
        a {
            &:hover {
                h2,h1 {
                    color: $blue;
                    transition: all 400ms;
                }
            }
        }
        .article-preview {
            // background-color: aqua;
            margin-bottom: 40px;
        
            h2 {
                font-size: 1.8rem;
                margin: 5px auto 15px auto;
            }
            p {
                font-size: 1.1rem;
                line-height: 1.4em;
                color: $grey-300;
                font-weight: 500;
                margin: 20px auto 10px auto;
            }
            .author-date {
                font-size: 1rem;
                color: $grey-300;
                font-weight: 500;
            }
            &:last-child{
                margin-bottom: 0px;
            }
            .pill {
                margin-bottom: 10px;
                color: $blue;
                text-transform: uppercase;
                font-weight: 800;
                // border-color: $blue;
                // border: none;
                // padding: 0;
            }
            .author-date {
                margin-top: 15px;
            }
        }
    }
}
.blog-article {
    
    .header {
        margin-top: 20px;
        background-color: $bg-light-grey;
        padding: 50px 0px 10px 0px;
        .pill {
            text-transform: uppercase;
            margin-bottom: 20px;
            font-size: 1rem;
            font-weight: bold;
            color: $blue;
            border-radius: 24px;
            padding: 10px 20px;
            // border: 1px solid $white-grey;
            border: 1px solid $blue;
            display: inline-block;
        }
        .title-container {
            h1 {
                @media (min-width: 884px) {
                    font-size: 3.5rem;
                    line-height: 1.15em;
                }
                font-weight: 600;
                font-size: 2.5rem;
                line-height: 1.15em;
            }
        }
        .auth-container {
            .content {
                font-weight: 500;
                
                @media (min-width: 884px) {
                    display: flex;
                }
                svg {
                    margin-right: 10px;
                }
                .author-date {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                }
                .author-time {
                    display: flex;
                    margin-top: 10px;
                    @media (min-width: 884px) {
                        margin-top: 0px;
                        margin-left: 40px;
                    }
                    vertical-align: middle;
                    align-items: center;
                }
            }
        }
        .main-image {
            // width: 100%;
            // max-width: 600px;
            position: relative;
            // right: 1%;
            top: 40%;
            // transform: translate(-50%, -50%);
            img {
                // width: 100%;
                // max-width: 600px;
                // position: absolute;
                // right: 1%;
                // top: 40%;
                // transform: translate(-50%, -50%);
            }
        }
        
    }
    
    .desc-container {
        p {
            
            margin: 10px auto 30px auto;
            font-size: 1.3rem;
            line-height: 1.5em;
        }
        ol, ul {
            li { 
                margin: 40px auto;
                font-size: 1.2rem;
                line-height: 1.4em;
                h6 {
                    font-weight: 600;
                    margin-bottom: 5px;
                }
            }   
        }
        ol {
            li {
                text-decoration: dotted;
            }
        }
        ul {
            li {
                text-decoration: dotted;
            }
        }
        h5{
            margin: 100px auto 50px auto;
            font-size: 1.6rem;
            line-height: 1.4em;
            color: $black;
        }
    }
    
}