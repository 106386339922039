
@import './../../css/color-variables';

  .navigation {
    transition: all 500ms;
    @media (min-width: 784px) {
        padding-top: .5em;
    padding-bottom: .5em;
    }
    
    &.transparent {
        background-color: transparent;
        transition: all 500ms;
    }
    &.white {
        background-color: $white;
        transition: all 500ms;
    }

    position: fixed;
    z-index: 999999;
    background-color: $white;
    width: 100%;

    @media (min-width: 1025px) {
        flex-direction: row;
        justify-content: space-between;
    }
    @media (min-width: 769px) {
        flex-direction: column;
        align-items: center;
    }
   
    
    .container {
        @media (max-width: 784px) {
            padding-bottom: 0px !important;
        }
        .header-container{
            display: flex;
            .logo-container {
                align-items: center;
                justify-content: center;
                width: auto;
                img {
                    max-width: 45px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                }
                span {
                    display: inline-flex;
                    align-items: center;
                }
            }
            .navigation-sub {
                display: none;
                @media (min-width: 784px) {
                    display: flex;
                }
                
                .has-subnav {
                    span {
                      
                        display: inline-block;
                        transition: all 250ms;
                        svg {
                            vertical-align: middle;
                            transform: rotate(0deg);
                            transition: all 250ms;
                        }
                        &.on {
                            svg {
                                transform: rotate(180deg);
                                transition: all 250ms;
                            }
                            
                        }
                        &.off {
                            svg {
                                transform: rotate(0deg);
                                transition: all 250ms;
                            }
                           
                        }
                        
                    }
                }
                a.item {
                    text-decoration: none;
                    padding: 0px 15px;
                    color: #3E5060;
                    transition: all 250ms;
                    font-weight: 600;
                    font-size: 1.1rem;
                    &:hover {
                        color: #007FFF;
                        transition: all 250ms;
                    }
                    &.button {
                        background-color: black;
                        color: #fff;
                        padding: 5px 10px;
                        font-weight: 600;
                        font-size: 14px;
                        border-radius: 5px;
                        margin-left: 15px;
                        transition: all 250ms;
                        &:hover{
                            background-color: lighten(black, 5%);
                            transition: all 250ms;
                        }
                    }
                    &.why-meetapp {
                        @media (max-width: 856px) {
                            display: none;
                        }
                    }
                }
                &.right {
                    align-items: center;
                    margin-left: auto;
                }
                &.left {
                    align-items: center;
                    margin-left: 8%;
                }
            }
        }
       
    }
  }

  .hamburger {
    margin-left: auto;
    @media (min-width: 784px) {
        display: none;
    }
  }
  .mobile-menu {
    position: fixed;
    top: 100px;
    height: 100%;
    background-color: $white;
    width: 100%;
    z-index: 999;
   

    // position: absolute;
//   width: 300px;
  margin: -100px 0 0 0;
//   padding: 50px;
  padding-top:90px;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  
  transform-origin: 0% 0%;
  transform: translate(200%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  &.open {
    transform: none;
  }
    .container {
        padding-top: 0px !important;
    }
    a {
        display: block;
        font-size: 1.5rem;
        padding: 15px 0px;
        font-weight: 500;
        border-bottom: 1px solid lighten($grey-100, 6%);
        &:last-child, &:nth-child(n+3) {
            border-bottom: none;
        }
        span {
                      
            display: inline-block;
            transition: all 250ms;
            svg {
                vertical-align: middle;
                transform: rotate(0deg);
                transition: all 250ms;
                margin-left: 10px;
            }
            &.on {
                svg {
                    transform: rotate(180deg);
                    transition: all 250ms;
                }
                
            }
            &.off {
                svg {
                    transform: rotate(0deg);
                    transition: all 250ms;
                }
               
            }
            
        }
        &.button {
            background-color: #000;
            border-radius: 6px;
            color: $white;
            margin-top: 20px;
            text-align: center;
        }
       
    }
    .resource-subnav {
        max-height: 0;
        overflow: hidden;
        text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-duration: 0.2s;
        transition-property: max-height;
        z-index: 1;
        position: relative;
        margin-top: 10px;
        &.open {
            max-height: 100rem;
            transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
            transition-duration: 0.2s;
            transition-property: max-height;
        }
        a {
            font-size: 1.2rem;
            border-bottom: none;
            padding: 12px 0px;
        }
    }
    @media (min-width: 784px) {
        display: none;
    }
  }



  .sub-header-container {
    background-color: $white;
    position: fixed;
    z-index: 99999;
    width: 100%;
    top: 100px;
    
    padding-bottom: 20px;
    &.off {
        transition: all 1000ms;
        transform: translateY(-200vh);
        opacity: 0;
        .container {
            transition: all 100ms;
            opacity: 0;
            transform: translateY(-10vh);
        }
    }
    &.on {   
        transition: all 500ms;
        transform: translateY(0vh);
        opacity: 1;
        filter: drop-shadow(0px 0px 100px #39393d54);
        .container {
            
                transition: all 1000ms;
                opacity: 1;
                transform: translateY(0vh);
                
        }
    }
    .container { 
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        .col {
            padding: 40px 20px;
            @media (max-width: 856px) {
                &.featured{
                    display: none;
                }
                padding: 0;
            }
            .col-sm-4 {
                @media (max-width: 856px) {
                    width: 50% !important;
                }
            }
        }
        h6{ 
            font-size: 1.5rem;
            font-weight: 400;
            color: $grey-400;
            margin-bottom: 10px;
            padding: 10px 15px;
        }
        .line {
            margin:0px 15px 15px 15px;
        }
        .navmenu-card-wrapper {
            @media (max-width: 784px) {
                display: none;
            }
        }
   
        .navmenu-item {
            margin-bottom: 20px;
            padding: 10px 15px;
            transition: all 350ms;
            &:hover {
                transition: all 350ms;
                background-color: $bg-light-grey;
                border-radius: 4px;
            }
           
            .title {
                font-size: 1rem;
                font-weight: 600;
                color: $grey-400;
                margin-bottom: 5px;
            }
            .text { 
                font-size: .85rem;
                color: $grey-250;
                font-weight: 400;
            }
        }
        .navmenu-card{
            @media (max-width: 784px) {
                display: none;
            }
            background-color: $white;
            border-radius: 6px;
            padding: 10px 15px;
            margin: 20px 15px;
            transition: all 400ms;
            border: 1px solid transparent;
            &:hover {
                transition: all 400ms;
                border: 1px solid lighten($bg-dark-blue, 10%);
                // background-color: lighten($white-grey, 2%);
            }
            .eyebrow {
                font-size: .8rem;
                font-weight: 600;
                color: $grey-200;
            }
            .text {
                margin: 5px auto;
                font-size: 0.85rem;
                color: $grey-400;
            }
            .cta {
                font-size: .75rem;
                color: $vivid-blue;
                font-weight: 600;
                text-transform: capitalize;
            }
        }
        .featured {
            @media (max-width: 856px) {
                display: none;
            }
            p {
                margin: 20px auto 30px auto;
                font-size: 1.1rem;
                line-height: 1.5rem;
                color: $grey-400;
            }
            a {
                background-color: $orange;
                padding: 15px 40px;
                border-radius: 6px;
                color: $black;
                font-size: .9rem;
                font-weight: 600;
                margin-top: 100px !important;
            }
        }
    }
}



 
  
 .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .open .hamRotate {
    transform: rotate(45deg);
  }
  .open .hamRotate180 {
    transform: rotate(180deg);
  }
  .line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:#000;
    stroke-width:5.5;
    stroke-linecap:round;
  }
  .ham1 .top {
    stroke-dasharray: 40 139;
  }
  .ham1 .bottom {
    stroke-dasharray: 40 180;
  }
  .open .ham1 .top {
    stroke-dashoffset: -98px;
  }
  .open .ham1 .bottom {
    stroke-dashoffset: -138px;
  }
  

  
  

  
  
  

  
  
  

  

  
 
 
  
  
  
  
  
  
     
  
  