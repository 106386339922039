.list-item {
    text-align: left;
    padding: 0;

    @media (max-width: 784px) {
        text-align: center;
        margin: 20px auto;
    }

    span {
        margin: 0;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0;
        font-weight: 700;
        color: lighten(#3E5060, 10%);
    }
    li {
        margin-top: 5px;
        
        font-size: 1rem;
        line-height: 1.35em;
        letter-spacing: 0px;
        font-weight: 400;
        list-style-type: none;
        a {
            text-decoration: none;
            color: darken(#A0AAB4, 5%) !important;
            transition: all 250ms;
            &:hover {
                color: #007FFF !important;
                transition: all 250ms;
            }
        }
    }
}